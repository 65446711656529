import styled from "styled-components";
import TextItem from "./ui/TextItem";
import SocialLinks from "./SocialLinks";
import ContactForm from "./ContactForm";
import { fromSmall } from "../styles/mediaQueries";
import { light, primaryBackground, secondaryDark } from "../styles/colors";

interface Props {
  showTitle?: boolean
  first?: boolean
  showIntro?: boolean
  showSocials?: boolean
  showContactInfo?: boolean
  topMargin?: boolean
}

const ContactInfo = styled.section`
  width: 100vw;
  padding: 1rem;
  
  ${fromSmall} {
    width: 30rem;
  }
`

const Container = styled.div<{$topMargin: boolean}>`
  background-color: ${secondaryDark};
  width: 100vw;
  margin: ${props => props.$topMargin ? '3rem' : '0'} 0 1.5rem;
  padding: 0.2rem 0 0.5rem;
  position: relative;
  z-index: 100;

  h2 {
    color: ${light};
    margin: 0 0 0.2em;
    font-size: 2.2rem;
  }

  label {
    color: ${light};
  }

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: ${primaryBackground};
    transform: rotate(1deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export default function AboutMe ({first = false, showTitle = true, showIntro = true, showContactInfo = true, showSocials = false, topMargin = true}: Readonly<Props>) {
  return (
    <Container $topMargin={topMargin}>
      <TextItem first={first}>
        {/* {showTitle && <h3>A little about me</h3>} */}
        {/* {showIntro && <p>Claudia is a London-based illustrator and comic artist with a passion for <Emphasis>bold colours and science-fiction stories</Emphasis> (as you probably have noticed).</p>} */}

        {showContactInfo && <>
          <ContactInfo>
            <h2>Get in touch:</h2>
            <ContactForm />
          </ContactInfo>
        </>}

        {showSocials && <SocialLinks short />}
      </TextItem>
    </Container>
  )
}
