export const primary = '#165DB9'
export const primaryDark = '#150578'
export const primarySubdued = '#D2DBE8';
export const primaryBackground = '#1e164c'
export const secondary = '#FFBD2E'
export const secondaryDark = '#E37D29'

export const light  = '#FFFFFF'
export const dark = '#3c3c3c'
export const grey = '#808080'
export const lightGrey = '#E0E0E0'
