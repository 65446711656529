import { keyframes } from "styled-components";

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const loadingAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0.4;
  }
`;
