import styled from 'styled-components'
import { Gallery, Item } from 'react-photoswipe-gallery';
import { ChildrenFnProps } from 'react-photoswipe-gallery/dist/types';
import { useImageSize } from 'react-image-size';
import { PortfolioImage, PortfolioImageKeys, defaultPortfolioImages, getImageForProject } from '../../data/portfolio'
import { breakpoints, fromMedium } from '../../styles/mediaQueries';
import { light, primaryDark } from '../../styles/colors';
import { emphasis } from '../../styles/fonts';
import { DNoiseCss } from '../ui/ImageNoise';
import { fadeInAnimation, loadingAnimation } from '../../utils/animation';

interface Props {
  images?: PortfolioImageKeys;
}

const ProjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 20, 59, 0.9);
  color: ${light};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: ${emphasis};
  opacity: 0;
  transition: opacity 0.3s;
  padding: 1em;
  text-align: center;
  font-weight: bold;
`;

/* https://www.smashingmagazine.com/2018/02/generated-content-grid-layout/ */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Doesn't work */
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  width: 100vw;
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  padding: 1em;
  flex-wrap: wrap;
  grid-auto-flow: dense;

  ${fromMedium} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const GridItem = styled.a<{$spanColumns?: number, $preventCropping?: boolean}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeInAnimation} 0.2s;

  ${DNoiseCss};

  > img {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.$preventCropping ? 'contain' : 'cover'};
  }

  &:hover, &:focus {
    filter: contrast(120%);
  }

  &:hover ${ProjectOverlay}, &:focus ${ProjectOverlay} {
    opacity: 1;
  }
`

const ImageContainer = styled.div<{$spanColumns?: number, $loading?: boolean}>`
  display: block;
  background-color: ${props => props.$loading ? primaryDark : 'transparent'};
  pointer-events: ${props => props.$loading ? 'none' : 'all'};
  width: 100%;
  height: ${props => props.$loading ? 'auto' : '100%'};
  grid-column: span ${props => props.$spanColumns || 1};
  animation: ${loadingAnimation} 2s alternate;
  animation-iteration-count: ${props => props.$loading ? 'infinite' : '0'};
  transition: all 0.4s ease;
`

function ImageGalleryItem({ image, children }: { image: PortfolioImage, children: (props: ChildrenFnProps<HTMLElement>) => JSX.Element }) {
  const [dimensions, { loading, error }] = useImageSize(image.imageUrl);

  if (error) {
    return <></>;
  }

  return (
    <ImageContainer $loading={loading} $spanColumns={image.spanColumns}>
      <Item
        original={image.imageUrl}
        thumbnail={image.imageThumbnail}
        // caption={image.description || `Tags: ${image.type.join(', ')}`}
        caption={image.description}
        key={image.slug}
        id={image.slug}
        width={dimensions?.width}
        height={dimensions?.height} 
      >
        {children}
      </Item>
    </ImageContainer>
  );
}

// should receive filters as a prop
export function PortfolioGrid ({ images = defaultPortfolioImages }: Props) {
  const imageData = images.map(([project, image]) => getImageForProject(project, image)).filter(image => !!image);

  return (
    <Gallery id="portfolio-gallery" withCaption>
      <GridContainer>
        {imageData.map((image) => {
          return image && !image.hideFromGallery ? (
            <ImageGalleryItem image={image}>
              {({ ref, open }) => (
                <GridItem ref={ref} onClick={open} $spanColumns={image.spanColumns} $preventCropping={image.preventCropping} key={image.imageUrl}>
                  <img src={image.imageMidSize} alt={image.description} />
                  {/* <ProjectOverlay>{getProject(project)?.title}</ProjectOverlay> */}
                </GridItem>
              )}
            </ImageGalleryItem>
          ) : null
        })}
      </GridContainer>
    </Gallery>
  )
}
